/*!
 * Copyright © 2020-2021 Positive Transition LTD
 * All rights reserved
 * For more information, please visit https://plus-t.co.uk/license
 */

// This file contains SCSS mixins that should be shared among the entire site.
// AT NO POINT SHOULD IT CONTAIN ANY KIND OF PRINTING SCSS/CSS.

@mixin bench-nine {
    font-family: "BenchNine", sans-serif;
}

@mixin rubik {
    font-family: "Rubik", sans-serif;
}

@mixin full-absolute($top: true, $left: true, $bottom: true, $right: true, $pos: absolute) {
    position: $pos;

    @if $top {
        top: 0;
    }

    @if $left {
        left: 0;
    }

    @if $bottom {
        bottom: 0;
    }

    @if $right {
        right: 0;
    }
}

@mixin highlight($prop, $speed: 0.2s) {
    #{$prop}: var(--highlight-colour);
    transition: $prop $speed;
}